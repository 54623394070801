import { Container, Grid, Card } from "@mui/material";

export default function HelpScreen(props) {
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          style={{
            minHeight: "30vh",
            minWidth: "100%",
            backgroundColor: "#eaeaea",
            textAlign: "center",
          }}
        >
          <p className="companyName"> PurpleDoc </p>
        </Grid>
        <Grid
          item
          style={{
            marginTop: "10vh",
            minHeight: "50vh",
            textAlign: "center",
          }}
        >
          <Card
            style={{
              width: "35vh",
              height: "16vh",
              textAlign: "center",
              padding: "2vh",
              borderRadius: "3vh",
              border: "solid 0.5vh lightGrey",
            }}
          >
            <p
              style={{ fontSize: "2.5vh", color: "purple", fontWeight: "bold" }}
            >
              {" "}
              CONTACT LEAPKARE AT
            </p>

            <p
              style={{ fontSize: "2.5vh", color: "purple", fontWeight: "bold" }}
            >
              {" "}
              8754338501
            </p>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
