import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import axios from "axios";

function writeLog(message, type) {
  axios.post("/Logging", { message, type });
}

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBprb5AZDwMcK75ah7ZGa6Dc9Hl0F9QaIo",
  authDomain: "doctorful.firebaseapp.com",
  projectId: "doctorful",
  storageBucket: "doctorful.appspot.com",
  messagingSenderId: "277820788274",
  appId: "1:277820788274:web:25d9cbdb22685f0e2bb62b",
  measurementId: "G-B9CXYSSJ25",
});

export function getMessagingObject() {
  // [START messaging_get_messaging_object]
  const messaging = getMessaging(firebaseApp);
  return messaging;
  // [END messaging_get_messaging_object]
}

export function getDeviceToken() {
  const messaging = getMessaging(firebaseApp);
  // [START messaging_get_token]
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  return getToken(messaging, {
    vapidKey:
      "BH68KpJqZCrXZ8hr_FIjNQ8joLvycywBf7sZb8QDgrsxCpHp9W_4ZNEe8XkQtOt-Xabp0nMYqdK_KRPzWZqkuVI",
  });
  // .then((currentToken) => {
  //   if (currentToken) {
  //     // Send the token to your server and update the UI if necessary
  //     // ...
  //     writeLog(`Retrieving token success,  ${currentToken}`, "INFO");
  //     localStorage.setItem("deviceToken", JSON.stringify(currentToken));
  //   } else {
  //     // Show permission request UI
  //     requestPermission();
  //     console.log(
  //       "No registration token available. Request permission to generate one."
  //     );
  //     writeLog(
  //       "No registration token available. Request permission to generate one"
  //     );
  //     // ...
  //   }
  // })
  // .catch((err) => {
  //   console.log("An error occurred while retrieving token. ", err);
  //   writeLog(`An error occurred while retrieving token,  ${err}`, "ERROR");
  //   // ...
  // });
  // [END messaging_get_token]
}

export function requestPermission() {
  // [START messaging_request_permission]
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      writeLog("Notification permission granted", "INFO");
      // TODO(developer): Retrieve a registration token for use with FCM.
      // ...
    } else {
      console.log("Unable to get permission to notify.");
      writeLog("Unable to get permission to notify", "INFO");
    }
  });
  // [END messaging_request_permission]
}

// import { getMessaging, getToken } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyBprb5AZDwMcK75ah7ZGa6Dc9Hl0F9QaIo",
//   authDomain: "doctorful.firebaseapp.com",
//   projectId: "doctorful",
//   storageBucket: "doctorful.appspot.com",
//   messagingSenderId: "277820788274",
//   appId: "1:277820788274:web:25d9cbdb22685f0e2bb62b",
//   measurementId: "G-B9CXYSSJ25",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);

// export const requestDeviceToken = () => {
//   if (Notification.permission === "granted") {
//     return getToken(messaging, {
//       vapidKey:
//         "BH68KpJqZCrXZ8hr_FIjNQ8joLvycywBf7sZb8QDgrsxCpHp9W_4ZNEe8XkQtOt-Xabp0nMYqdK_KRPzWZqkuVI",
//     });
//   }
// };
