import { Container, Grid, TextField, Button } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState, useEffect } from "react";
import axios from "axios";

export default function AccountScreen(props) {
  let [patientName, setPatientName] = useState("");
  let [patientNumber, setPatientNumber] = useState("");
  let [patientAge, setPatientAge] = useState("");

  useEffect(() => {
    let val = JSON.parse(localStorage.getItem("patientInfo"));
    if (val) {
      setPatientName(val.name);
      setPatientNumber(val.number);
      setPatientAge(val.age);
    }
  }, []);

  const handlePatientName = (e) => {
    e.preventDefault();
    setPatientName(e.target.value);
  };

  const handlePatientNumber = (e) => {
    e.preventDefault();
    setPatientNumber(e.target.value);
  };

  const handlePatientAge = (e) => {
    e.preventDefault();
    setPatientAge(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let patientID = "";
    let val = JSON.parse(localStorage.getItem("patientID"));
    if (val) patientID = val;

    const allowLogin =
      patientName.length > 0 &&
      patientNumber.length > 0 &&
      patientNumber.length > 0;

    if (!allowLogin) {
      alert("Please enter all details to proceed");
      return;
    }

    const obj = {
      name: patientName,
      number: patientNumber,
      age: patientAge,
    };

    localStorage.setItem("patientInfo", JSON.stringify(obj));
    obj.patientID = patientID;
    axios
      .post("/PatientDetails", obj)
      .then((res) => {
        localStorage.setItem("patientID", JSON.stringify(res.data));
        console.log(
          "Patient AccountScreen: Patient information added successfully!"
        );
        alert("Patient details updated");
      })
      .catch((err) =>
        console.log(
          "Patient AccountScreen: Could not save patient details",
          err
        )
      );
  };

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          style={{
            minHeight: "30vh",
            minWidth: "100%",
            backgroundColor: "#eaeaea",
            textAlign: "center",
          }}
        >
          <p className="companyName"> PurpleDoc </p>
        </Grid>
        <Grid
          item
          style={{
            minHeight: "50vh",
            textAlign: "center",
            marginTop: "10vh",
          }}
        >
          <form onSubmit={handleFormSubmit}>
            <legend
              style={{
                marginTop: "7px",
                fontWeight: "bold",
                marginLeft: "5px",
                color: "purple",
              }}
            >
              {" "}
              Patient Details
            </legend>
            <FavoriteIcon />
            <br />
            <TextField
              size="small"
              id="outlined-basic"
              label="Patient Name"
              variant="outlined"
              value={patientName}
              onChange={handlePatientName}
            />
            <br />
            <br />
            <TextField
              size="small"
              id="outlined-basic"
              label="Patient Number"
              variant="outlined"
              value={patientNumber}
              onChange={handlePatientNumber}
            />
            <br />
            <br />
            <TextField
              size="small"
              id="outlined-basic"
              label="Patient Age"
              variant="outlined"
              value={patientAge}
              onChange={handlePatientAge}
            />
            <br />
            <br />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={handleFormSubmit}
            >
              Save
            </Button>
          </form>
          <br />
          <br />
        </Grid>
      </Grid>
    </Container>
  );
}
