import React, { useEffect, useState } from "react";
import axios from "axios";
import PatientScreen from "./patientScreen";

function writeLog(message, type) {
  axios.post("/Logging", { message, type });
}

function Patient(props) {
  let [doctorInfo, setDoctorInfo] = useState({
    name: "",
    number: "",
    qualification: "",
    specializaion: "",
  });

  let [clinicInfo, setClinicInfo] = useState({
    name: "",
    number: "",
    address: "",
    days: { start: "", end: "" },
    timing: { start: "", end: "" },
  });

  let [isValidScan, setIsValidScan] = useState(false);

  useEffect(() => {
    let doctorID = props.doctorID;
    let clinicID = props.clinicID;

    if (doctorID.length === 0 && clinicID.length === 0) {
      writeLog("Patient: Invalid QR code scanned");
      alert("Invalid QR code scanned, please scan the correct QR code!");
      return;
    }

    let params = { doctorID };
    if (doctorID.length > 0) {
      axios
        .get("/DoctorDetails", { params })
        .then((res) => {
          if (res.data) {
            setDoctorInfo(res.data);
            setIsValidScan(true);
          }
        })
        .catch((err) => {
          writeLog("Patient: Unable to fetch doctor information");
          alert(
            "Unable to fetch Doctor Information, please check your internet connection and try again"
          );
          return;
        });
    }
    params = { clinicID };
    if (clinicID.length > 0) {
      axios
        .get("/ClinicDetails", { params })
        .then((res) => {
          if (res.data) {
            setClinicInfo(res.data);
            setIsValidScan(true);
          }
        })
        .catch((err) => {
          writeLog('writeLog("Patient: Unable to fetch clinic information");');
          alert(
            "Unable to fetch clinic information, please check your internet connection and try again"
          );
          return;
        });
    }
  }, [props]);

  return (
    <div>
      {isValidScan && <PatientScreen info={doctorInfo} info2={clinicInfo} />}
    </div>
  );
}

export default Patient;
