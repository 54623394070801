import { useEffect, useState } from "react";
import Patient from "./components/Patient";
import { requestPermission } from "./components/firebase";
import QRCodeScanner from "./components/scanQRCode.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import HelpIcon from "@mui/icons-material/Help";
import AccountIcon from "@mui/icons-material/AccountCircle";
import HelpScreen from "./components/helpScreen";
import AccountScreen from "./components/accountScreen";
const channel = new BroadcastChannel("sw-channel");

function App() {
  let [appDoctorID, setAppDoctorID] = useState("");
  let [appClinicID, setAppClinicID] = useState("");
  let [isHelpClicked, setHelpClicked] = useState(false);
  let [isAccountClicked, setAccountClicked] = useState(false);

  function writeLog(message, type) {
    axios.post("/Logging", { message, type });
  }

  const setScanDetails = (scanResult) => {
    const url = new URL(scanResult);
    const searchParams = url.searchParams;

    const doctorID = searchParams.get("doctorID");
    const clinicID = searchParams.get("clinicID");

    if (doctorID.length > 0) {
      writeLog(
        `Came here through app QR code scan, found doctorID - ${doctorID}, clinicID - ${clinicID}`,
        "INFO"
      );
      localStorage.setItem("doctorID", JSON.stringify(doctorID));
      localStorage.setItem("clinicID", JSON.stringify(clinicID));
      setAppDoctorID(doctorID);
      setAppClinicID(clinicID);
    }
  };

  useEffect(() => {
    console.log("New code!!!");
    const handleMessage = (event) => {
      if (event.data && event.data.action === "reload") {
        console.log("Received background message: ", event.data);
        writeLog(`Received background notification: ${event.data}`, "INFO");
        setTimeout(() => {
          setAppDoctorID("");
          setAppClinicID("");
        }, 10000);
        window.location.reload(true);
      }
    };

    // Add an event listener for the 'reload' event
    channel.addEventListener("message", handleMessage);
    requestPermission();

    let doctorID = localStorage.getItem("doctorID")
      ? JSON.parse(localStorage.getItem("doctorID"))
      : "";
    let clinicID = localStorage.getItem("clinicID")
      ? JSON.parse(localStorage.getItem("clinicID"))
      : "";

    if (doctorID.length > 0) {
      setAppDoctorID(doctorID);
      setAppClinicID(clinicID);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      doctorID = urlParams.get("doctorID") ? urlParams.get("doctorID") : "";
      clinicID = urlParams.get("clinicID") ? urlParams.get("clinicID") : "";

      if (doctorID.length > 0) {
        writeLog(
          `Came here through camera scan, found doctorID - ${doctorID}, clinicID - ${clinicID}`,
          "INFO"
        );
        localStorage.setItem("doctorID", JSON.stringify(doctorID));
        localStorage.setItem("clinicID", JSON.stringify(clinicID));
        setAppDoctorID(doctorID);
        setAppClinicID(clinicID);
      }
    }
    // Clean up the event listener on component unmount
    return () => {
      channel.removeEventListener("message", handleMessage);
      channel.close();
    };
  }, []);

  function FooterNavigation() {
    return (
      <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="Token"
            icon={<HomeIcon color="action" />}
            onClick={handleHomeClick}
          />
          <BottomNavigationAction
            label="Help"
            icon={<HelpIcon color="action" />}
            onClick={handleHelpClick}
          />
          <BottomNavigationAction
            label="Account"
            icon={<AccountIcon color="action" />}
            onClick={handleAccountClick}
          />
        </BottomNavigation>
      </div>
    );
  }

  const handleHelpClick = (e) => {
    setHelpClicked(true);
    setAccountClicked(false);
  };

  const handleHomeClick = (e) => {
    setHelpClicked(false);
    setAccountClicked(false);
  };

  const handleAccountClick = (e) => {
    setAccountClicked(true);
    setHelpClicked(false);
  };

  if (isHelpClicked) {
    return (
      <div>
        <HelpScreen />
        <FooterNavigation />
      </div>
    );
  }

  if (isAccountClicked) {
    return (
      <div>
        <AccountScreen />
        <FooterNavigation />
      </div>
    );
  }

  return (
    <div>
      {appDoctorID && <Patient doctorID={appDoctorID} clinicID={appClinicID} />}
      {appDoctorID.length === 0 && (
        <Router>
          <Routes>
            <Route
              exact
              path="/scan"
              element={<QRCodeScanner qrCodeSuccessCallback={setScanDetails} />}
            />
          </Routes>
        </Router>
      )}
      <FooterNavigation />
    </div>
  );
}

export default App;
