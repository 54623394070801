import "./styles.css";

export default function DoctorDetails(props) {
  return (
    <div>
      <p className="clinicName"> {props.info2.name} </p>
      <p className="doctorInfo1">
        Dr. {props.info.name}, {props.info.qualification}
      </p>
      <p className="doctorInfo1">({props.info.specialization})</p>
      <p className="clinicAddress">
        {" "}
        <span style={{ color: "purple" }}>Address: </span>
        <span>{props.info2.address}</span>
      </p>
      <p>
        {" "}
        <span style={{ color: "purple" }}>Phone:</span>{" "}
        <span> {props.info.number} </span>{" "}
      </p>
      <p>
        {" "}
        <span style={{ color: "purple" }}>Timings: </span>
        <span>{props.info2.days.start}</span> -
        <span>{props.info2.days.end}</span>,
        <span>{props.info2.timing.start} </span> -
        <span>{props.info2.timing.end}</span>
      </p>
      <p></p>
    </div>
  );
}
