import { Container, Grid, Card, Button, CircularProgress } from "@mui/material";
import DoctorDetails from "./doctorDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import { getMessagingObject, getDeviceToken } from "./firebase";
import { onMessage } from "firebase/messaging";
import useSound from "use-sound";

function writeLog(message, type) {
  axios.post("/Logging", { message, type });
}

export default function PatientScreen(props) {
  let [tokenNumber, setTokenNumber] = useState(0);
  let [currentTokenNumber, setCurrentTokenNumer] = useState(0);
  let [isTokenCalled, setTokenCalled] = useState(false);
  let [checkFlag, setCheckFlag] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [sound] = useSound("./token.mp3");

  useEffect(() => {
    // Dummy call to bear FCM (no service worker) error
    getDeviceToken();

    const messaging = getMessagingObject();
    //Listen for foreground message
    onMessage(messaging, (payload) => {
      writeLog(`Received foreground payload: ${payload}`, "INFO");
      setTokenCalled(true);
      setTimeout(() => {
        localStorage.removeItem("doctorID");
        localStorage.removeItem("clinicID");
        localStorage.setItem("currentToken", JSON.stringify(0));
        setTokenNumber(0);
        setTokenCalled(false);
      }, 10000);
      sound();
    });

    let val = JSON.parse(localStorage.getItem("currentToken"));
    if (val) setTokenNumber(val);

    let doctorID = "",
      clinicID = "";

    val = JSON.parse(localStorage.getItem("doctorID"));
    if (val) doctorID = val;
    val = JSON.parse(localStorage.getItem("clinicID"));
    if (val) clinicID = val;

    writeLog(
      `PatientScreen: Found doctorID - ${doctorID} and clinicID - ${clinicID}`,
      "INFO"
    );

    const params = {
      doctorID,
      clinicID,
    };

    if (doctorID.length > 0) {
      axios
        .get("/DoctorDetails", { params })
        .then((doc) => {
          const bool =
            Number(doc.data.currentToken) > 0 &&
            Number(doc.data.currentToken) === tokenNumber;
          setTokenCalled(bool);

          // This is for handling background notifications that trigger window.location.reload(true) once it receives the background notification
          if (bool === true) {
            writeLog(
              `PatientScreen: Patient ${doc.data.tokenNumber} was called`
            );
            setTimeout(() => {
              localStorage.removeItem("doctorID");
              localStorage.removeItem("clinicID");
              localStorage.setItem("currentToken", JSON.stringify(0));
              setTokenNumber(0);
              setTokenCalled(false);
            }, 10000);
          }
        })
        .catch((err) => {
          console.log("Cannot call patient!", err);
          writeLog(`Cannot call patient: ${err}`, "ERROR");
        });
    }
  }, [tokenNumber, isTokenCalled, sound]);

  const handleGetTokenNumber = (e) => {
    e.preventDefault();
    setIsLoading(true);

    getDeviceToken()
      .then((dToken) => {
        let doctorID = "",
          clinicID = "";
        let val = JSON.parse(localStorage.getItem("doctorID"));
        if (val) doctorID = val;
        val = JSON.parse(localStorage.getItem("clinicID"));
        if (val) clinicID = val;

        writeLog(
          `Generated device token successfully!: ${dToken}, doctorID - ${doctorID}, clinicID - ${clinicID}`,
          "INFO"
        );

        if (dToken.length > 0) {
          const params = {
            doctorID,
            clinicID,
          };
          if (doctorID.length > 0) {
            axios
              .get("/TokenAction", { params })
              .then((doc) => {
                //Below case needs to be worked upon further
                if (
                  doc.data === "Clinic not open, please try after some time"
                ) {
                  setIsLoading(false);
                  alert("Clinic closed!");
                  localStorage.setItem("currentToken", JSON.stringify(0));
                  return;
                } else {
                  axios
                    .post("/Appointments", {
                      doctorID: doctorID,
                      clinicID: clinicID,
                      patientID: dToken,
                      tokenNumber: doc.data.tokenNumber,
                    })
                    .then(() => {
                      console.log(
                        "PatientScreen: Appointment details loaded successfully"
                      );
                      writeLog(
                        "PatientScreen: Appointment details loaded successfully",
                        "INFO"
                      );
                      setTokenNumber(doc.data.tokenNumber);
                      localStorage.setItem(
                        "currentToken",
                        JSON.stringify(doc.data.tokenNumber)
                      );
                      setIsLoading(false);
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      console.log(
                        "PatientScreen: Error loading appointment to database: ",
                        err
                      );
                      writeLog(
                        `PatientScreen: Error loading appointment to database: ${err}`,
                        "ERROR"
                      );
                    });
                }
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("PatientScreen: Unable to get token number: ", err);
                writeLog(
                  `PatientScreen: Unable to get token number: ${err}`,
                  "ERROR"
                );
              });
          } else {
            setIsLoading(false);
            alert("Please scan the QR code again");
            return;
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        alert("Could not generate token, please try again");
        writeLog(`${err}`, "ERROR");
        return;
      });
  };

  const handleGetCurrentToken = (e) => {
    e.preventDefault();
    let doctorID = "";
    let val = JSON.parse(localStorage.getItem("doctorID"));
    if (val) doctorID = val;

    const params = {
      doctorID,
    };

    if (doctorID.length > 0) {
      axios
        .get("/DoctorDetails", { params })
        .then((res) => {
          writeLog(
            `Fetched current token ${res.data.currentToken} successfully`,
            "INFO"
          );
          setCurrentTokenNumer(res.data.currentToken);
          setCheckFlag(true);
          setTimeout(() => setCheckFlag(false), 3000);
        })
        .catch((err) => {
          console.log("Failed to get current token number: ", err);
          writeLog(`Failed to get current token number: ${err}`, "ERROR");
        });
    } else {
      console.log(
        "doctorID empty, cannot fetch doctor details to get active token"
      );
      writeLog(
        "doctorID empty, cannot fetch doctor details to get active token",
        "INFO"
      );
    }
  };

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          style={{
            minHeight: "30vh",
            minWidth: "100%",
            backgroundColor: "#eaeaea",
            textAlign: "center",
          }}
        >
          <DoctorDetails info={props.info} info2={props.info2} />
        </Grid>
        <Grid
          item
          style={{
            minHeight: "55vh",
            marginTop: "5vh",
            textAlign: "center",
          }}
        >
          {Number(tokenNumber) === 0 && (
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={handleGetTokenNumber}
            >
              Get Token
            </Button>
          )}
          <br />
          <br />
          {isLoading === true && <CircularProgress color="secondary" />}
          {Number(tokenNumber) > 0 && isTokenCalled === false && (
            <Grid item>
              <Card
                style={{
                  width: "35vh",
                  height: "16vh",
                  textAlign: "center",
                  padding: "2vh",
                  borderRadius: "3vh",
                  border: "solid 0.5vh lightGrey",
                }}
              >
                <p
                  style={{
                    fontSize: "2.5vh",
                    color: "purple",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  YOUR TOKEN NUMBER IS
                </p>
                <p
                  style={{
                    fontSize: "2.5vh",
                    color: "purple",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {tokenNumber}{" "}
                </p>
              </Card>
              <br />
              <br />
              <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={handleGetCurrentToken}
              >
                CHECK ACTIVE TOKEN
              </Button>
              <br />
              <br />
              {Number(currentTokenNumber) > 0 && checkFlag && (
                <small> ACTIVE TOKEN IS {currentTokenNumber}</small>
              )}
            </Grid>
          )}
          {isTokenCalled === true && Number(tokenNumber) > 0 && (
            <Card
              style={{
                width: "35vh",
                height: "16vh",
                textAlign: "center",
                padding: "2vh",
                borderRadius: "3vh",
                border: "solid 0.5vh lightGrey",
              }}
            >
              <p
                style={{
                  fontSize: "2.5vh",
                  color: "purple",
                  fontWeight: "bold",
                }}
              >
                {" "}
                TOKEN NUMBER {tokenNumber}
              </p>

              <p
                style={{
                  fontSize: "2.5vh",
                  color: "purple",
                  fontWeight: "bold",
                }}
              >
                PLEASE COME IN
              </p>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
