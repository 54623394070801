//import { Html5QrcodeScanner } from "html5-qrcode";
import { useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { Container, Grid } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import IconButton from "@mui/material/IconButton";
import "./styles.css";
import axios from "axios";

function QRCodeScanner(props) {
  let [scan, setScan] = useState("");
  let [isScanClicked, setScanClicked] = useState(false);
  function writeLog(message, type) {
    axios.post("/Logging", { message, type });
  }

  const cameraScan = () => {
    console.log("Into HTML5 QR code library");
    const html5QrCode = new Html5Qrcode("reader");
    //const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    html5QrCode
      .start(
        { facingMode: "environment" },
        {
          fps: 10, // Optional, frame per seconds for qr code scanning
          qrbox: { width: 250, height: 250 }, // Optional, if you want bounded box UI
        },
        (decodedText, decodedResult) => {
          setScanClicked(true);
          // do something when code is read
          console.log("Successfully scanned", decodedText, decodedResult);
          writeLog(
            `Successfully scanned, ${decodedText}, ${decodedResult}`,
            "INFO"
          );
          setScan(decodedText);
          props.qrCodeSuccessCallback(decodedText);
          html5QrCode
            .stop()
            .then((ignore) => {
              // QR Code scanning is stopped.
              writeLog("QR code scanning stopped successfully", "INFO");
            })
            .catch((err) => {
              // Stop failed, handle it.
              writeLog("QR code scanning could not be stopped", "INFO");
            });
        },
        (errorMessage) => {
          setScanClicked(true);
          // parse error, ignore it.
          writeLog(`QR code scanning parse error: ${errorMessage}`, "ERROR");
        }
      )
      .catch((err) => {
        // Start failed, handle it.
        console.log("Failed to start camera, ", err);
        writeLog(`Failed to start camera: ${err}`, "ERROR");
      });
  };

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          style={{
            minHeight: "30vh",
            minWidth: "100%",
            backgroundColor: "#eaeaea",
            textAlign: "center",
          }}
        >
          <p className="companyName"> PurpleDoc </p>
        </Grid>
        <Grid
          item
          style={{
            minHeight: "45vh",
            minWidth: "100%",
            marginTop: "15vh",
            textAlign: "center",
          }}
        >
          {isScanClicked === false && (
            <IconButton onClick={cameraScan}>
              <QrCodeScannerIcon style={{ fontSize: "100", color: "purple" }} />
            </IconButton>
          )}

          {scan ? (
            <div>{scan}</div>
          ) : (
            <div
              id="reader"
              width="250px"
              height="250px"
              style={{ color: "purple" }}
            >
              {" "}
              Click to scan QR code
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default QRCodeScanner;
